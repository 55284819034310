import React from "react"
import { graphql } from "gatsby"
import Layout from "~components/layouts/default"
import ContactUs from "~components/global/contact-us"
import Figure from "~components/common/figure"
import SEO from "~components/common/seo"
import AniLink from "~components/common/anilink"
import Container from "~components/common/container"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="KammTec – Betriebseinrichtung, Ladenbau und Innenausbau aus Meinerzhagen" />
    <Container>
      <div className="xl:w-2/3 xl:mx-auto">
        <div className="gutter my-screen-lg lg:w-5/8">
          <h1>Holz und Stahl aus dem Sauerland.</h1>
          <p>Wir erschaffen aus Holz und Stahl hochwertige Produkte für zeitlose Betriebseinrichtungen, kreativen Ladenbau und moderne Möbeldesigns aus Meisterhand.</p>
          <AniLink to="/unternehmen" className="accessory">Mehr über uns</AniLink>
        </div>
      </div>
    </Container>
    <Container>
      <div className="my-screen-lg lg:flex lg:items-center">
        <div className="gutter lg:w-7/12 lg:order-last xl:w-2/3 xl:order-none">
          {data.imageHalle &&
          <Figure fluid={data.imageHalle.childImageSharp.fluid} alt="Foto der Produktionshalle" title="Blick in die Produktion" />
          }
        </div>
        <div className="gutter lg:w-5/12 xl:w-1/3">
          <h2>Seit 20 Jahren 110%.</h2>
          <p>Seit der Gründung in 2000 sind wir stetig gewachsen und geben jeden Tag unser Bestes für die Wünsche unserer Kunden.</p>
          <AniLink to="/kontakt" className="accessory">Kontakt aufnehmen</AniLink>
        </div>
      </div>
    </Container>
    <Container>
      <div className="my-screen-lg lg:flex lg:items-center">
        <div className="gutter lg:w-5/12 xl:w-1/3">
          <h2>Holz sagt mehr als 1000 Worte.</h2>
          <p>Ein Einblick in die Ergebnisse unserer Arbeit mit Bildern zu ausgewählten Projekten.</p>
          <AniLink to="/referenzen" className="accessory">Referenzen anschauen</AniLink>
        </div>
        <div className="gutter lg:w-7/12 lg:order-first xl:w-2/3 xl:order-none">
          {data.imageVflFanshop &&
          <Figure fluid={data.imageVflFanshop.childImageSharp.fluid} alt="Foto der Produktionshalle" title="Blick in die Produktion" />
          }
        </div>
      </div>
    </Container>
    <ContactUs slug="marcel-meier" />
  </Layout>
)

export const query = graphql`
  query {
    imageHalle: file(relativePath: { eq: "blick-in-die-halle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 880, maxHeight: 495) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    imageVflFanshop: file(relativePath: { eq: "references/vfl-fanshop/vfl-fanshop-01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 880, maxHeight: 495) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
